body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
    text-rendering: optimizeLegibility;
}

h1 {
    font-size: 3rem;
    letter-spacing: 1px;
    margin: 0;
    padding: 0;
    text-align: center;
    opacity: 0.5;
}

.app-outer {
    display: -webkit-flex;
    display: flex;
    /*flex-direction: row;*/
    /*align-items: stretch;*/
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    min-height: 375px;
}

.app-inner {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: stretch;
            align-items: stretch;
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.tag-cloud {
    -webkit-flex: 1 1;
            flex: 1 1;
}

.tag-cloud > div {
    -webkit-transition: 2.0s;
    transition: 2.0s;
}

/*.tag-item-wrapper {*/
    /*cursor: pointer;*/
/*}*/

/*.tag-item-wrapper:hover {*/
    /*z-index: 100;*/
/*}*/

/*.tag-item-wrapper:hover .tag-item-tooltip {*/
    /*display: block;*/
/*}*/

/*.tag-item-tooltip {*/
    /*background: #c01916;*/
    /*color: white;*/
    /*margin-top: 5px;*/
    /*z-index: 100;*/
    /*display: none;*/
    /*padding: 15px;*/
    /*font-size: 14px;*/
    /*line-height: 1;*/
    /*position: absolute;*/
    /*min-width: 60px;*/
    /*left: 50%;*/
    /*transform: translateX(-50%);*/
    /*text-align: center;*/
/*}*/
body {
  /*background-color: #f9e5fa;*/
}

.App {
  /*text-align: center;*/
  /*background-color: #282c34;*/
}

.App-logo {
  /*animation: App-logo-spin infinite 20s linear;*/
  padding: 20px;
  height: 10vmin;
  pointer-events: none;
}

.App-header {
  /*min-height: 100vh;*/
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  /*align-items: center;*/
  /*justify-content: center;*/
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/*.apper-wrapper {*/
  /*background-color: red;*/

/*}*/
/*@keyframes App-logo-spin {*/
  /*from {*/
    /*transform: rotate(0deg);*/
  /*}*/
  /*to {*/
    /*transform: rotate(360deg);*/
  /*}*/
/*}*/

