body {
  /*background-color: #f9e5fa;*/
}

.App {
  /*text-align: center;*/
  /*background-color: #282c34;*/
}

.App-logo {
  /*animation: App-logo-spin infinite 20s linear;*/
  padding: 20px;
  height: 10vmin;
  pointer-events: none;
}

.App-header {
  /*min-height: 100vh;*/
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  /*justify-content: center;*/
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/*.apper-wrapper {*/
  /*background-color: red;*/

/*}*/
/*@keyframes App-logo-spin {*/
  /*from {*/
    /*transform: rotate(0deg);*/
  /*}*/
  /*to {*/
    /*transform: rotate(360deg);*/
  /*}*/
/*}*/
