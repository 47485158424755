body {
    text-rendering: optimizeLegibility;
}

h1 {
    font-size: 3rem;
    letter-spacing: 1px;
    margin: 0;
    padding: 0;
    text-align: center;
    opacity: 0.5;
}

.app-outer {
    display: flex;
    /*flex-direction: row;*/
    /*align-items: stretch;*/
    justify-content: center;
    width: 100%;
    min-height: 375px;
}

.app-inner {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-grow: 1;
}

.tag-cloud {
    flex: 1;
}

.tag-cloud > div {
    transition: 2.0s;
}

/*.tag-item-wrapper {*/
    /*cursor: pointer;*/
/*}*/

/*.tag-item-wrapper:hover {*/
    /*z-index: 100;*/
/*}*/

/*.tag-item-wrapper:hover .tag-item-tooltip {*/
    /*display: block;*/
/*}*/

/*.tag-item-tooltip {*/
    /*background: #c01916;*/
    /*color: white;*/
    /*margin-top: 5px;*/
    /*z-index: 100;*/
    /*display: none;*/
    /*padding: 15px;*/
    /*font-size: 14px;*/
    /*line-height: 1;*/
    /*position: absolute;*/
    /*min-width: 60px;*/
    /*left: 50%;*/
    /*transform: translateX(-50%);*/
    /*text-align: center;*/
/*}*/